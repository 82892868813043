* {
    box-sizing: border-box;
  }
  
body {
    background-color: rgb(245, 245, 245);
    color: rgb(0, 0, 0);
}

a {
  font-size: 1.2rem;
}

h4 {
  margin-top: 0.4rem;
  border-radius: 8px;
  padding: 0.6rem;
  text-align: center;
  background-color: rgb(98, 179, 245);
  font-size: 2rem;
  color:#fefeff;
}

h5 {
  font-size: 1.2rem;
  color:#0084ff;
}

h6 {
  font-size: 0.8rem;
  text-decoration: none;
  color:#8c8d8d;
}

h7 {
  font-size: 0.7rem;
}

.blueBand {
  margin-top: 2rem;
}

.superposition {
  margin-left: 2rem;
  position: absolute;
  margin-top: 2px;
  margin-right: 6px;
}

.legalMentions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*****************************  Cards from Bootstrap  *******************************/
/* Float four columns side by side */
.column {
    float: left;
    width: 25%;
    padding: 0 10px;
  }
  
  /* Remove extra left and right margins, due to padding */
  .row {margin: 0 -5px;}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /**************************************** Responsive columns *******************************************/
  @media screen and (min-width:199px) and (max-width:400px){
    .column {
      width: 50%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      margin-left: 2rem;
      position: absolute;
      top: 100px;
      right: 250px;
    }
    
  }
  @media screen  and (min-width:401px) and (max-width:600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      margin-left: 2rem;
      top: -55px;
      right: 50px;
    }
  }
  @media screen and (min-width:601px) and (max-width:899px){
    .column {
      width: 50%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      position: absolute;
      top: -30px;
      right: 140px;
    }
  }
  @media screen and (min-width:900px) and (max-width:999px){
    .column {
      width: 33%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      position: absolute;
      top: 25px;
      right: 335px;
    }
  }
  @media screen and (min-width:1000px)and (max-width:1200px){
    .column {
      width: 25%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      position: absolute;
      top: 25px;
      right: 355px;
    }
  }
  @media screen and (min-width:1201px)and (max-width:1500px){
    .column {
      width: 25%;
      display: block;
      margin-bottom: 20px;
    }
    .superposition {
      position: absolute;
      top: 65px;
      right: 455px;
    }
  }
  
  /*********************** cards *****************************/
  .card {
    box-shadow: 0 2px 2px 0 rgba(119, 119, 119, 0.2);
    border-radius: 8px;
    text-align: center;
    background-color: #ffffff;
    transition: 0.4s;
    margin: 0.2rem;
    padding-bottom: 0.2rem; 
    height: auto;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card:hover {
    box-shadow: 1px 1px 19px rgba(104, 104, 104, 0.4);
}
.card .mb-3 {
  font-size:3rem ;
  color: white;
}

/************************************************************************************/

.container {
    border: 1px solid rgb(255, 255, 255);
}

.planContainer {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(255, 255, 255);
  background-color: #045d91;
  color: #ffffff;
}

.planDuSite {
  border: 1px solid rgb(255, 255, 255);
  background-color: #0869a1;
  width: 14em;
  justify-content: center;
  align-items: center;
  margin: 52px;
  font-size: large;
}

.b-container-fluid {
  margin-left: 6rem;
  margin-right: 6rem;
}
.row {
    border: 1px solid rgb(253, 253, 255);  
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
img .logo {
    width: 25rem;
    height: Auto;
    box-shadow: none;
}

.tocover {
  display: flex;
  background-image: url('./assets/images/nouveauBackground.png');
}

.bandeau {
    max-width: 100%;
    z-index: 1;
}
.apropos {
 background-color: #e1e1e4;
 padding: 32px;
 color: rgb(0, 0, 0);
 border-radius: 8px;
 margin-bottom: 1rem;
}
img {
    border-radius: 3px;
    transition: 0.4s;
}

i {
  color: #a3c4c4;
}
/****************************  BUTTONS   *******************************/
.btn {
  margin-left: 0.5rem;
  padding: 0.2rem;
}

p {
    padding: 0.8rem;
}
.site {
    min-height: 100vh;
    margin-bottom: -140px;
}

footer, .minSite {
    height: 140px;
    color: white;
    text-align: center;
}
.mentionslegales:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

/********************  Social Media Icons*******************/
.bg-info {
    align-items: center;
    margin-top: 5px;
    border-radius: 8px;
}
.fa {
    font-size:36px;
    margin-left: 3rem;
    margin-top: 1rem;
    margin-right: 2rem;
}
.bulle {
  margin-left: 2rem;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  background-image:
  radial-gradient(
    circle at top left,
    #ffffff,
    #549eb1,
    #0765a3,
    #000b22
  );
  opacity: 0.5;
  transition: 0.9s;
}

.bulle:hover {
  box-shadow: 2px 6px 5px rgb(1, 1, 78);
  display: inline-block;
  margin: 0.5rem 0;
  width: 215px;
  height: 28px;
  opacity: 0.0;
  background-image:
  radial-gradient(
    circle at top right,
    #ffffff,
    #77c6da,
    #49dff3,
    #000b22
  );
}
.superposition {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  padding-left: 33rem;
  padding-top: 31rem;
}

.citation {
  z-index: 3;
  position: absolute;
  right: 8rem;
  top: 35em;
}


.filt {
  color: black;
}
option {
  color: black;
}
.bandanim {
  display: flex;
}

.bandeauMer {
  display: flex;
  align-items: flex-start;
}

  .waves {
    display: block;
    width: 880px;
    height: auto;
    margin-top: 155px;
    margin-left: -880px;
    z-index: 2;
    position: absolute;

  }

  .bandeauMer2 {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .buoy {
    display: block;
    width: 200px;
    height: auto;
    z-index: 2;
    position: absolute;
    margin-top: 390px;
    margin-left: -540px;
    animation: rotation2 4s ease-in-out infinite;
  }

  @keyframes rotation2 {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(-5deg);
    }
  }
  .bluewaves {
    display: block;
    width: 890px;
    height: auto;
    margin-top: 510px;
    margin-left: -890px;
    z-index: 3;
    position: absolute;
  }

  @media screen and (max-width: 1024px) {
    .bluewaves {
      width: 697px;
      height: auto;
      margin-top: 500px;
      margin-left: -695px;
      z-index: 3;
    }

    .bg-info {
      margin-bottom: -30px;
    }

    .citation {
      margin-left: 20rem;
      margin-top: 33rem;
    }
  }

  @media screen and (max-width: 991px) and (min-width: 766px) {
    .bluewaves {
      width: 687px;
      height: auto;
      margin-top: 340px;
      margin-left: -687px;
      z-index: 3;
    }

    .buoy {
      display: block;
      width: 130px;
      height: auto;
      margin-top: 265px;
      z-index: 2;
      position: absolute;
      margin-left: -380px;
      animation: rotation2 4s linear infinite;
    }
    .superposition {
      display: none;
    }

    h4 {
      margin-top: 2rem;
    }
  
    
  }

  @media screen and (max-width: 767px) and (min-width: 536px) {
    .bluewaves {
      width: 502px;
      height: auto;
      margin-top: 250px;
      margin-left: -507px;
      z-index: 3;
    }

    .superposition {
      display: none;
    }

    h4 {
      margin-top: 2rem;
    }

    .buoy {
      display: block;
      width: 110px;
      height: auto;
      margin-top: 185px;
      z-index: 2;
      position: absolute;
      margin-left: -300px;
      animation: rotation2 4s linear infinite;
    }

  }

  @media screen and (max-width: 665px) {
    .citation {
      margin-left: 8rem;
      margin-top: 20rem;
    }

    h4 {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 537px)and (min-width: 429px) {
    .bluewaves {
      width: 405px;
      height: auto;
      margin-top: 205px;
      margin-left: -405px;
      z-index: 3;
    }
    .citation {
      margin-left: 6rem;
      margin-top: 15rem;
    }

    .buoy {
      display: block;
      width: 100px;
      height: auto;
      margin-top: 180px;
      z-index: 2;
      position: absolute;
      margin-left: -300px;
      animation: rotation2 4s linear infinite;
    }

    .superposition {
      display: none;
    }
    
  }

  @media screen and (max-width: 477px) {
    .buoy {
      display: block;
      width: 90px;
      height: auto;
      margin-top: 165px;
      z-index: 2;
      position: absolute;
      margin-left: -250px;
      animation: rotation2 4s linear infinite;
    }

  }
  
  @media screen and (max-width: 430px) {
    .bluewaves {
      width: 395px;
      height: auto;
      margin-top: 199px;
      margin-left: -395px;
      z-index: 3;
    }
    .buoy {
      display: block;
      width: 60px;
      height: auto;
      margin-top: 160px;
      z-index: 2;
      position: absolute;
      margin-left: -180px;
      animation: rotation2 4s linear infinite;
    }
    .citation {
      margin-left: 2rem;
      margin-top: 14rem;
    }
    .superposition {
      display: none;
    }
  }

  @media screen and (max-width: 360px) {
    .superposition {
      display: none;
    }

    .buoy {
      display: block;
      width: 80px;
      height: auto;
      margin-top: 115px;
      z-index: 2;
      position: absolute;
      margin-left: -190px;
      animation: rotation2 4s linear infinite;
    }
  }
  
  @media screen and (max-width: 320px) {
    .citation {
      margin-left: 1.3rem;
      margin-top: 11rem;
    }
    .superposition {
      display: none;
    }

    

    h4 {
      margin-top: 2rem;
    }

  }

  @media screen and (max-width: 319px) {
    .superposition {
      display: none;
    }

    .buoy {
      display: block;
      width: 130px;
      height: auto;
      margin-top: 105px;
      z-index: 2;
      position: absolute;
      margin-left: -380px;
      animation: rotation2 4s linear infinite;
    }

    h4 {
      margin-top: 0.2rem;
    }

  }

  @media screen and (max-width: 344px) {
    .bluewaves {
      width: 290px;
      height: auto;
      margin-top: 150px;
      margin-left: -290px;
      z-index: 3;
    }
    .buoy {
      display: block;
      width: 60px;
      height: auto;
      margin-top: 105px;
      z-index: 2;
      position: absolute;
      margin-left: -150px;
      animation: rotation2 4s linear infinite;
    }
  }
  
  @media screen and (max-width: 320px) {
    .bluewaves {
      width: 280px;
      height: auto;
      margin-top: 145px;
      margin-left: -285px;
      z-index: 3;
    }
    .buoy {
      display: block;
      width: 70px;
      height: auto;
      margin-top: 100px;
      z-index: 2;
      position: absolute;
      margin-left: -180px;
      animation: rotation2 4s linear infinite;
    }

    .bg-info {
      margin-bottom: 0px;
    }

  }

  @media screen and (max-width: 287px) {
    .superposition {
      display: none;
    }
    .buoy {
      display: block;
      width: 70px;
      height: auto;
      margin-top: 80px;
      z-index: 2;
      position: absolute;
      margin-left: -150px;
      animation: rotation2 4s linear infinite;
    }
  }

  @media screen and (max-width: 280px) {
    .superposition {
      display: none;
    }
  }
  
  
